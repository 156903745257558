body {
  margin: 0px;
  font-family: sans-serif;
}

#background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    30deg,
    rgba(128, 91, 16, 0.85),
    rgba(0, 75, 35, 0.85)
  );
}

#background-formulario {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

#form {
  width: 450px;
  height: 450px;
  background: linear-gradient(
    30deg,
    rgba(128, 91, 16, 0.85),
    rgba(49, 87, 44, 0.85)
  );
  box-shadow: 0px 0px 10px rgba(52, 52, 52, 0.35);
  backdrop-filter: blur(5px);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  align-items: center;
  animation: fadeIn 0.5s linear forwards;
}

#form h3 {
  text-align: center;
  font-size: 30px;
  color: white;
  cursor: default;
  -webkit-user-select: none;
  animation: topIn 0.5s linear forwards;
}

#form .text {
  width: calc(100% - 40px);
  min-height: 35px;
  margin-bottom: 20px;
  background: none;
  border: none;
  background: rgba(255, 255, 255, 0.11);
  outline: none;
  padding-left: 10px;
  color: white;
  font-size: 20px;
  animation: leftIn 0.5s linear forwards;
  animation-delay: calc(0.08s * var(--i));
  transform: translateX(-100%);
  opacity: 0;
}

#form .text::placeholder {
  color: rgba(255, 255, 255, 0.89);
}

#form .boton {
  min-height: 35px;
  min-width: 200px;
  background: none;
  border: 1px solid white;
  color: white;
  font-size: 15px;
  border-radius: 30px;
  -webkit-transition: all 0.5s ease;
  animation: rigIn 0.5s linear forwards;
  opacity: 0;
  margin-top: 25px;
  margin-bottom: 15px;
}

#form .boton:hover {
  background: rgba(21, 78, 11, 0.651);
  -webkit-transition: all 0.5s ease;
}

#form a {
  color: white;
  margin-top: 30px;
  margin-bottom: 20px;
  animation: bttmIn 0.6s linear forwards;
}

.creditos {
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.38);
  cursor: default;
  -webkit-user-select: none;
  animation: bttmIn 0.6s linear forwards;
}

/*Animaciones*/

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*Top*/

@keyframes topIn {
  0% {
    transform: translateY(-300%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/*left*/

@keyframes leftIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes rigIn {
  0% {
    transform: translateX(200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes bttmIn {
  0% {
    transform: translateY(300%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.viewForm {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boxOrdenarPreguntas {
  width: auto;
}

.g-3 {
  height: 500px;
}

.errorCredenciales {
  color: rgb(250, 28, 28);
  font-size: 20px;
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input[type="date"] {
  background-color: #0080ff;
  padding: 15px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-family: "Roboto Mono", monospace;
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-color: #ffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}


/*BOTONES EN MODIFICACION DE TRAMITE*/
.btrn6 {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 20px;
  padding: 30px 25px;
  margin-bottom: 10px;
  font-size: 18px;
  margin-top: 20px;
  font-size: 18px;
}

.btrn6:hover {
  background: rgba(247, 247, 247, 0.75);
  border: 1px solid #d7d7d7;
  text-decoration: none;
  outline: none;
  color: rgb(50, 50, 50);
  transition: color 400ms, background-color 400ms;
}
/*FIN DE LOS BOTONES*/
.gridBotones {
  display: grid;
  grid-template-columns: repeat(1,1fr);
}

@media (min-width: 768px) {
    .gridBotones {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10px;
}
}


.gridF {
  display: grid;
  grid-template-columns: 1, repeat(1fr);
  grid-template-rows: auto 70px;
}

@media (min-width: 768px) {
    .gridF {
  display: grid;
  grid-template-columns: 1, repeat(1fr);
  grid-template-rows: auto 50px;
}
}

.cajitaFlex{
  display: flex;
  flex-direction: column;
}

.flygps {
  position: -webkit-sticky !important;
position: sticky !important;
top: 20px !important;
}

.frutiColor{
  background-color: rgb(253, 247, 226) !important; 
  border-radius: 5px !important;
  z-index: 3 !important;
}

.rdw-option-wrapper {
  border-radius: 5px !important;
  border-radius: 10px !important ;
  background-color:  rgb(253, 247, 226)  !important;
  border-bottom-color: rgb(253, 247, 226) !important;
}
.rdw-option-wrapper:hover {
  border-radius: 5px !important;
  background-color:  rgb(219, 208, 172) !important;
  border-bottom-color: rgb(219, 208, 172) !important;
}
.rdw-dropdown-selectedtext {
  color:black !important;
  border-radius: 5px !important;
  border-radius: 10px  !important;
  background-color:  rgb(253, 247, 226)  !important;
  border-bottom-color: rgb(253, 247, 226) !important;
}
.rdw-dropdown-selectedtext:hover {
  color:black !important;
  border-radius: 5px !important;
  background-color:  rgb(219, 208, 172) !important;
  border-bottom-color: rgb(219, 208, 172) !important;
}

.rdw-option-active {
  border-radius: 5px !important;
background-color:  rgb(219, 208, 172) !important;
  border-bottom-color: rgb(219, 208, 172) !important;
}

.dimention {
transform: scale(0.7);
}

.cajitaShow {
  max-height: 450px !important;
  margin: 0 0 !important;
  padding: 0 !important;
}