.botonAceptar {
    width: 100%;
}

.margen_text {
    height:50px;
    width:100%;
    border:2px solid rgb(197, 197, 197);
    border-radius: 5px;
}
.margen_text > p {
    width: fit-content;
    margin-top:-10px;
    margin-left:5px;
    background:rgb(255, 255, 255);
}

.margen_text > div {
    padding-left: 15px;
    padding-top: 5px;
}

.margen_textEditor {
    width:100%;
    border:1px solid rgb(219, 219, 219);
    border-radius: 5px;
}
.margen_textEditor > p {
    width: fit-content;
    font-size: large;
    margin-top:-10px;
    margin-left:5px;
    background:rgb(255, 255, 255);
}

.margen_textEditor > div {
    padding: 15px;
}