:root {
  --blanco: #fff;
  --oscuro: #212121;
  --gris: #757575;
  --grisClaro: #dfe9f3;
  --funte: ;
  --verdeClaro: #61866d;
  --verdeOscuro: #1c3d14;
  --amarilloClaro: #ab9c63;
  --amarilloOscuro: #9c6e12;
  --tamañoAlto: 17px;
  --tamañoAncho: 4px;
  --posicionX: 7px;
  --posicionY: 14px;
}

ul {
  list-style-type: disc;
}

li {
  margin-left: 30px;
  padding-left: 5px;
}

.Imagen__Inicio {
  width: 230px;
  margin-left: 20px;
}

.contenedor {
  max-width: 120em;
  margin: 0 auto;
}

@media (min-width: 2048px) {
  .contenedor2 {
    max-width: 100em;
    margin: 0 auto;
  }
}

@media (min-width: 1080px) {
  .contenedor2 {
    max-width: 80em;
    margin: 0 auto;
  }
}

.cudBtn {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 10px;
}

.sombra {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--blanco);
  padding: 0.5em;
  background-image: linear-gradient(
    to right,
    #61866d,
    #6f8d6a,
    #819366,
    #959863,
    #ab9c63
  );
}

.sombra2 {
  box-shadow: 0px 5px 15px 0px rgba(112, 112, 112, 0.2);
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .sombra2 {
    box-shadow: 0px 5px 15px 0px rgba(112, 112, 112, 0.2);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
}

.sombraCell {
  padding: 20px;
  margin-bottom: 20px;
}

.sombra3 {
  box-shadow: 0px 5px 15px 0px rgba(112, 112, 112, 0.2);
  padding: 20px;
  border-radius: 5px;
}

.sombra4 {
  box-shadow: 0px 5px 15px 0px rgba(112, 112, 112, 0.2);
  padding: 20px;
  border-radius: 5px;
  padding: 20px;
  min-width: 338px;
}


.pad {
  margin-bottom: 20px;
}

.pad2 {
  margin-top: 20px;
}

.badk {
  background-image: url("https://www.uaemex.mx/images/2021/bottom/UAEM-DTIc-6.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-color: #333333;
  color: #fff;
  font-weight: bold;
}

.titleMod {
  text-align: center;
  font-weight: bold;
  font-size: 33px;
  color: var(--oscuro);
}

@media (min-width: 480px) {
  .titleMod {
    text-align: center;
    font-weight: bold;
    font-size: 35px;
    color: var(--oscuro);
  }
}

.titleMod1 {
  text-align: center;
  font-weight: bold;
  font-size: 35px;
  color: var(--blanco);
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
    0px -5px 35px rgba(255, 255, 255, 0.3);
}

@media (min-width: 768px) {
  .titleMod1 {
    text-align: center;
    font-weight: bold;
    font-size: 45px;
    color: var(--blanco);
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

.titleMod2 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: var(--oscuro);
}

/*Scroll Tramites*/
.ps {
  max-width: 500px;
  height: 500px;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  overflow: auto;
  text-align: justify;
}

@media (min-width: 768px) {
  .ps {
    max-width: 500px;
    height: 500px;
    margin: 0 auto;
    padding: 0;
    background: #fff;
    overflow: auto;
    text-align: justify;
  }
}

@media (min-width: 2048px) {
  .ps {
    max-width: 500px;
    height: 575px;
    margin: 0 auto;
    padding: 0;
    background: #fff;
    overflow: auto;
    text-align: justify;
  }
}

.sclp::-webkit-scrollbar {
  width: 7px;
}

.sclp::-webkit-scrollbar-thumb {
  background: #373a47;
  border-radius: 5px;
}

/*model de tiron de editor */
.ps2 {
  max-width: 700px;
  height: 500px;
  margin: 0 auto;
  padding: 10px;
  background: #fff;
  overflow: auto;
  text-align: justify;
}

.sclp2::-webkit-scrollbar {
  width: 7px;
}

.sclp2::-webkit-scrollbar-thumb {
  background: #373a47;
  border-radius: 5px;
}

/*---------FIN-----------*/

/*Scroll Ayuda*/
.ps2 {
  height: 780px;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  overflow: auto;
}

@media (min-width: 768px) {
  .ps2 {
    height: 528px;
    margin: 0 auto;
    padding: 0;
    background: #fff;
    overflow: auto;
  }
}

@media (min-width: 2048px) {
  .ps2 {
    height: 790px;
    margin: 0 auto;
    padding: 10px;
    background: #fff;
    overflow: auto;
  }
}

/*Ideas*/
.clo {
  width: 100%;
  height: 60vh;
}

.jus {
  text-align: justify;
}

.cen {
  text-align: center;
}

.cenItem {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/*CARTAS LAS QUE SALEN EN TRAMITE*/
.boxMd {
  display: flex;
  justify-self: auto;
  text-align: left;
  background: hsl(47, 87%, 94%);
}

.titelMd {
  font-weight: bold;
  font-size: 25px;
  color: var(--oscuro);
}

.derItem {
  margin-left: auto;
  padding-left: 15px;
}

.sigNo {
  border-radius: 50%;
  background: hsl(47, 65%, 84%);
  border: 1px solid hsl(47, 87%, 94%);
}

.sigNo:hover {
  background: rgb(219, 208, 172);
  border: 1px solid rgb(219, 208, 172);
}

.tamNo {
  width: 35px;
  height: 35px;
}

.icoNo {
  position: relative;
  top: 0px;
  left: -3px;
  color: var(--oscuro);
}
/*FIN DE LAS CARTAS*/

/*Btn*/
.btrn {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.btrn:hover {
  background: rgba(247, 247, 247, 0.75) !important;
  border: 1px solid #d7d7d7 !important;
  text-decoration: none;
  outline: none;
  color: #777 !important;
}
/*header btn*/
.btrn2 {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 10px;
  margin-bottom: 3px;
}

.btrn2:hover {
  background: rgba(247, 247, 247, 0.75);
  border: 1px solid #d7d7d7;
  text-decoration: none;
  outline: none;
  color: rgb(50, 50, 50);
  transition: color 400ms, background-color 400ms;
}

.btrn2d {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 10px;
  margin-bottom: 3px;
  opacity: 0.5;
}

.btrn2d:focus {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 10px;
  margin-bottom: 3px;
}
/*BOTON DE MODEL FOOTER*/
.btrn3 {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

.btrn3:hover {
  background: rgba(247, 247, 247, 0.75);
  border: 1px solid #d7d7d7;
  text-decoration: none;
  outline: none;
  color: rgb(50, 50, 50);
  transition: color 400ms, background-color 400ms;
}
/*FIN DEL BOTON MODALS*/

/*BOTON PARA CANCELAR O ELIMINAR*/
.btrn3c {
  color: #ffffff;
  border-color: #681d1d;
  background: #681d1d;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

.btrn3c:hover {
  background: rgba(247, 247, 247, 0.75);
  border: 1px solid #d7d7d7;
  text-decoration: none;
  outline: none;
  color: rgb(50, 50, 50);
  transition: color 400ms, background-color 400ms;
}
/*FIN DEL BOTON DE CANCELAR*/

/*BOTON ESPACIAL*/

.btrn6 {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

.btrn6c {
  color: #ffffff;
  border-color: #681d1d;
  background: #681d1d;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

/*BOTON ESPECAIL FIN*/

/*boton para enviar formulario*/
.btrn4 {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 18px;
  margin-top: 20px;
}

.btrn4:hover {
  background: rgba(247, 247, 247, 0.75);
  border: 1px solid #d7d7d7;
  text-decoration: none;
  outline: none;
  color: rgb(50, 50, 50);
  transition: color 400ms, background-color 400ms;
}

/*btn para header de configuracion de los tramites*/
.btrn5 {
  color: #ffffff;
  border-color: #1c3d14;
  background: #1c3d14;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px 35px;
  margin-bottom: 10px;
  font-size: 18px;
  margin: 10px 30px;
}

.btrn5:hover {
  background: rgba(247, 247, 247, 0.75);
  border: 1px solid #d7d7d7;
  text-decoration: none;
  outline: none;
  color: rgb(50, 50, 50);
  transition: color 400ms, background-color 400ms;
}

/*FIN DE LOS BTN*/

/*HEADER GRID CONFIGURACION DE LOS TRAMITES*/
.gridAd {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 10px;
}
/**/

/*GRID HEADER*/
.gridH {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 10px;
}

/*GRID DE ADMIN*/
.botonA {
  grid-column: 2 / 3;
}

.gridT {
  display: grid;
}

@media (min-width: 768px) {
  .gridT {
    display: block;
    justify-content: center;
    text-align: center;
  }

  .btrn5 {
    color: #ffffff;
    border-color: #1c3d14;
    background: #1c3d14;
    border-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px 35px;
    margin-bottom: 10px;
    font-size: 18px;
    margin: auto 30px;
  }

  .btrn5:hover {
    background: rgba(247, 247, 247, 0.75);
    border: 1px solid #d7d7d7;
    text-decoration: none;
    outline: none;
    color: rgb(50, 50, 50);
    transition: color 400ms, background-color 400ms;
  }
}

/* GRID */
.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 30px;
  row-gap: 10px;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 30px;
  row-gap: 10px;
}

@media (min-width: 768px) {
  .grid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 10px;
  }
}

/* estilo del burger */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 25px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--oscuro);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #eee;
  position: fixed;
  width: 35px;
  height: 15px;
  right: 16px;
  top: 36px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 55px;
  width: 55px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--oscuro);
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #faf3e7;
  padding: 2.5em 1em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #faf3e7;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/*Foorer*/
.gridFoo {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 30px;
  row-gap: 10px;
}

.informacion__Encargado {
  text-align: center;
  margin-left: 10px;
}

.imagen__Escudo {
  display: block;
  margin: auto;
}

.informacion__Unidad {
  text-align: center;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .gridFoo {
    grid-template-columns: repeat(3, 1fr);
  }
  .informacion__Encargado {
    text-align: left;
    padding-top: 23px;
  }

  .imagen__Escudo {
    display: block;
    margin: auto;
  }

  .informacion__Unidad {
    text-align: right;
    padding-top: 23px;
  }
}

.bot {
  position: relative;
  min-height: 73vh;
}

/*RAYA DE LEANDREO*/

.hr {
  background: #8f8f8f;
  width: 100%;
  height: 2px;
  border: none;
  margin: 0px 0px 10px 0px;
  border-radius: 50px;
}

/*
Animacion de carga
*/

.loader,
.loader:before,
.loader:after {
  background: #368c47;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #368c47;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/*
Fin Animacion de carga
*/

/*Load 2*/
.loader2 {
  color: #368c47;
  font-size: 50px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Finalizacion de animacion loading 2*/
/* Finalizacion de animacion loading 2*/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Finalizacion de animacion loading 2*/

/*CREANDO COMPONENTES DE BULMA*/
/*CREANDO FOORER DEL MODAL*/
.fomodal {
  background-image: url("https://www.uaemex.mx/images/2021/bottom/UAEM-DTIc-6.png");
  background-color: #333333;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-top: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}
.hemodal {
  display: flex;
  background-color: var(--amarilloClaro);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--blanco);
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
}
/*FINN*/

/*bodymodal*/
.bodyModal3 {
  -webkit-overflow-scrolling: touch;
  background: var(--blanco);
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
  text-align: justify;
  height: 400px;
}

@media (min-width: 768px) {
  .bodyModal3 {
    -webkit-overflow-scrolling: touch;
    background: var(--blanco);
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
    text-align: justify;
  }
}

.bodyModal {
  -webkit-overflow-scrolling: touch;
  background: var(--blanco);
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
  text-align: justify;
  height: auto;
}

/*bodymodal*/
.bodyModal2 {
  -webkit-overflow-scrolling: touch;
  background: var(--blanco);
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
  text-align: justify;
  height: 400px;
}
/*prueba boton cerrar*/

@media (min-width: 768px) {
  .bodyModal2 {
    -webkit-overflow-scrolling: touch;
    background: var(--blanco);
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
    text-align: justify;
  }
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 35px;
  height: 35px;
  background: rgb(151, 134, 85);
  border-radius: 25px;
  border: 2px solid rgb(171, 156, 99);
}

.close:hover {
  background: rgb(119, 104, 44);
}
.close:before {
  position: absolute;
  content: "";
  width: var(--tamañoAlto);
  height: var(--tamañoAncho);
  background: white;
  transform: rotate(45deg);
  top: var(--posicionY);
  left: var(--posicionX);
  border-radius: 5px;
}

.close:after {
  position: absolute;
  content: "";
  width: var(--tamañoAlto);
  height: var(--tamañoAncho);
  background: white;
  transform: rotate(-45deg);
  top: var(--posicionY);
  left: var(--posicionX);
  border-radius: 5px;
}

/*QUITAR EL AUMENTADOR*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"] {
  -webkit-appearance: textfield !important;
  margin: 0;
  -moz-appearance: textfield !important;
}
/*FIN DE /*QUITAR EL AUMENTADOR*/

/*DISEÑO DE RADIOS*/
.radioF input[type="radio"]:checked + label::before {
  display: none;
}

.radioF .radio label::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  position: absolute;
  left: 15px;
  border-radius: 50%;
  background: none;
  border: 3px solid #367219;
}

.radioF input[type="radio"]:checked + label {
  padding: 5px 15px;
  background: #367219;
  border-radius: 2px;
  color: #fff;
}

.radioF .radio label {
  color: #367219;
  padding: 5px 15px 5px 40px;
  display: inline-block;
  position: relative;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.radioF .radio label:hover {
  background: rgba(255, 255, 51, 0.61);
}

input[type="radio"] {
  display: none;
}

.radio {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 10px;
}

@media (min-width: 768px) {
  .radio {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 30px;
    row-gap: 10px;
  }
}

/*CUANDO HAY POCOS*/
.radio2 {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .radio2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.radioF input[type="radio"]:checked + label::before {
  display: none;
}

.radioF .radio2 label::before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  position: absolute;
  left: 15px;
  border-radius: 50%;
  background: none;
  border: 3px solid #367219;
}

.radioF input[type="radio"]:checked + label {
  padding: 5px 15px;
  background: #367219;
  border-radius: 2px;
  color: #fff;
}

.radioF .radio2 label {
  color: #367219;
  padding: 5px 15px 5px 40px;
  display: inline-block;
  position: relative;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.radioF .radio2 label:hover {
  background: rgba(255, 255, 51, 0.61);
}

input[type="radio"] {
  display: none;
}

.styleLinea {
  border: 2px solid rgb(99, 99, 99);
  width: 100%;
}

/*CONTENEDOR DE LAS OPCIONES A EDITAR*/

@media (min-width: 768px) {
  .conteiner1 {
    max-width: 431px;
  }
}

.DayPicker_weekHeader_li {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  right: 99999999999999999px !important;
}
